<template>
    <div class="home">
        <van-nav-bar class="navbar" title="许可证下载" left-text="返回" :right-text="softName" left-arrow
            @click-left="onClickLeft" />
        <!-- <van-search v-model="searchValue" show-action placeholder="请输入订单信息/申请项目/机器码" @search="onSearch">
            <template #action>
                <van-icon name="clock-o" size="20" />
            </template>
</van-search> -->
        <!-- <div>
            <van-row type="flex" justify="center" class="title">
                <van-col span="11">
                    <van-icon name="tv-o" />
                    机器码
                </van-col>
                <van-col span="7">
                    <i class="iconfont icon-xiazai"></i>
                    许可证下载
                </van-col>
                <van-col span="4">创建时间</van-col>
            </van-row>
            <van-row type="flex" justify="center" v-for="item in list" :key="item.index" style="height: auto">
                <van-col span="11">{{ item.machinecode }}</van-col>
                <van-col span="7">
                    <van-button class="warnings" type="info" v-if="item.state == 0">
                        审核中
                    </van-button>
                    <van-button class="warnings" type="danger" v-if="item.state == 1" @click="downloadLicense(item)">
                        下载
                    </van-button>
                    <van-button class="warnings" type="default" ghost v-if="item.state == 2">已过期</van-button>
                    <van-button class="warnings" type="warning" ghost v-if="item.state == 3">已驳回</van-button>
                </van-col>
                <van-col span="4" id="Urgent">
                    <span v-show="item.creatortime">{{ item.creatortime | toDateText() }}</span>
                </van-col>
            </van-row>
        </div> -->
        <div class="licenselist">
            <div class="licenseItem" v-for="item in list" :key="item.index">
                <div class="machine">{{ item.machinecode }}</div>
                <div class="ops">
                    <div class="time">
                        <span v-show="item.creatortime">{{ item.creatortime | toDateText() }}</span>
                        <span v-show="item.state == 0">申请时间：{{ item.lastmodifytime | toDateText() }}</span>
                    </div>
                    <div class="button">
                        <el-button size="mini" round type="success" v-if="item.state == 0">
                            审核中
                        </el-button>
                        <el-button size="mini" round type="primary" v-if="item.state == 1"
                            @click="downloadLicense(item)">
                            下载
                        </el-button>
                        <el-button size="mini" round ghost v-if="item.state == 2">已过期</el-button>
                        <el-button size="mini" round ghost v-if="item.state == 3" type="danger">已驳回</el-button>
                    </div>
                </div>
                <div class="des" v-show="item.state == 3">
                    <el-input type="textarea" :rows="2" autosize v-model="item.backreason" :disabled="true">
                    </el-input>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getLicenseByPhone, downloadLicense, } from "@/assets/API/pay.js";
import "amfe-flexible/index";
import { Dialog } from 'vant';
import define from '@/utils/define';
export default {
    name: "Home",
    data() {
        return {
            softName: '',
            searchValue: '',
            loading: false,
            finished: false,
            phone: '',
            list: []
        };
    },
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
    mounted() {
        this.phone = this.$route.query.phoneNum;
        console.log(this.phone, 'uuu');
        // 根据手机号查询许可证数据
        getLicenseByPhone(this.phone).then(res => {
            this.list = res.data.filter(v => v.licensetype != 1);
            this.list.forEach(e => {
                if (e.state == 3) {
                    e.backreason = "驳回原因：" + e.backreason;
                }
            });
        });
    },
    watch: {
    },
    methods: {
        onLoad() {
            // 异步更新数据
            // setTimeout(() => {
            //     for (let i = 0; i < 10; i++) {
            //         this.list.push(this.list.length + 1);
            //     }

            //     // 加载状态结束
            //     this.loading = false;

            //     // 数据全部加载完成
            //     if (this.list.length >= 40) {
            //         this.finished = true;
            //     }
            // }, 1000);
        },
        onClickLeft() {
            let query = this.$route.query;
            this.$router.push({
                name: "apply", path: "/apply", query
            });
        },
        onSearch() { },
        getProductName(data) {
            let res = this.productList.filter(e => {
                return e.id == data;
            });
            if (res[0]) {
                return res[0].name;
            } else {
                return '';
            }
        },
        downloadLicense(data) {
            // 下载许可证
            downloadLicense(data.id).then(res => {
                let url = "/api/business/C_license/downloadFile?data=" + res.data;
                if (!url) return;
                // const baseUrl = url.indexOf("http") > -1 ? "" : this.define.comUrl;
                const baseUrl = url.indexOf("http") > -1 ? "" : this.define.APIURl;
                const a = document.createElement("a");
                a.setAttribute("download", res.data);
                let name = null;
                console.log(baseUrl, 'baseurl');
                const realUrl = baseUrl + url + (name ? "&name=" + name : "");
                a.setAttribute("href", realUrl);
                a.click();
            });
        },


    },
};
</script>
<style lang="scss" scoped>
.home {}

.totalContent {
    height: 100%;
    background-color: #FAFAFA;
    padding-bottom: 60px;

}

.itemContent {
    // height: 220px;
    opacity: 1;
    border-radius: 6px;
    background: #FFFFFF;
    margin: 10px;
    padding: 20px;

    .applyTitle {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 2px solid #F7F7F7;

        .applyTime {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 21.72px;
            color: rgba(56, 56, 56, 1);
        }

        .unactiveState {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 21.72px;
            color: rgba(86, 196, 81, 1);
        }
    }

    .applyContent {
        padding: 10px 0;

        .orderMes {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20.27px;
            color: rgba(56, 56, 56, 1);
            text-align: left;
            vertical-align: top;
            padding: 5px 0;

            .orderHead {
                width: 25%;
            }

            .orderDes {
                flex: 1;
            }

        }
    }

    .applyOps {
        display: flex;
        align-items: end;
        justify-content: flex-end;
    }
}

.chooseAll {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;

    .allText {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(128, 128, 128, 1);
        text-align: left;
        vertical-align: top;
    }
}

.applyAll {
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #FFFFFF;

    .applyallBtn {
        width: 100%;
    }
}

.navbar {
    ::v-deep .van-nav-bar__right {
        .van-nav-bar__text {
            color: #808080;
        }
    }
}

// .van-row {
//     height: auto;
//     line-height: 12px;
// }

.van-col {
    // font-size: 14px;
    border-bottom: 1px solid #dee1e6;
    word-break: break-all;
    text-align: center;
}

// ::v-deep.van-field__label {
//     // width: 4em;
// }

// ::v-deep.van-field--disabled .van-field__label {
//     color: #646566;
// }

// // ::v-deep.van-button--large {
// //     width: 93%;
// // }

// ::v-deep.van-cell {
//     // margin: 0.08667rem 0;
// }

// // .warnings {
// //     padding: 0.02rem 0.16rem;
// //     font-size: 25px;
// // }

// // ::v-deep.van-button--info {
// //     margin-left: 26.5px;
// // }
.licenselist {
    background-color: rgb(229, 229, 229, 0.5);
    padding-top: 5px;
    height: 100%;

    .licenseItem {
        background-color: #fff;
        border-radius: 10px;
        margin: 7px;
        padding: 15px;

        .machine {
            font-size: 15px;
            text-align: center;
            white-space: nowrap;
            border-bottom: 1px solid rgb(220, 229, 229, 1);
            padding-bottom: 10px;

        }

        .ops {
            padding: 10px;
            padding-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .time {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20.27px;
                color: rgba(128, 128, 128, 1);
                text-align: left;
                vertical-align: top;
            }
        }

        .des {
            margin-top: 10px;

            ::v-deep .el-textarea.is-disabled .el-textarea__inner {
                border-color: rgba(240, 68, 53, 1);
                color: rgba(240, 68, 53, 1);
            }
        }
    }
}
</style>